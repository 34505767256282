'use client';

import { BaseMap, JourneyLayers, UserTilesSource } from '@streetferret/map-web';
import { StreetFerretLogoWithText } from '@streetferret/ui-web/brand';
import { Button } from '@streetferret/ui-web/button';
import { useTheme } from 'next-themes';
import { useMemo } from 'react';
import { GeolocateControl, type LngLatBoundsLike } from 'react-map-gl';
import { JourneyLiveLayers } from './journey-live-layers';
import { type LiveMapOptions } from './live-map-options';
import { RouteStartEndMarkers } from './route-start-end-markers';

interface LiveMapProps {
  stravaId: number;
  tileVersion: number;
  token: string;
  bounds?: LngLatBoundsLike;
  journeyId: bigint;
  user: {
    firstname: string | null;
    lastname: string | null;
    profile: string | null;
  };
  mapStyleConfig?: {
    light: string;
    dark: string;
  };
  startDate: Date;
  startPoint?: {
    lat: number;
    lng: number;
  };
  endPoint?: {
    lat: number;
    lng: number;
  };
  options?: LiveMapOptions;
  children?: React.ReactNode;
}

export function LiveMap({
  stravaId,
  tileVersion,
  token,
  bounds,
  journeyId,
  mapStyleConfig,
  user,
  startDate,
  startPoint,
  endPoint,
  options,
  children,
}: LiveMapProps) {
  const { resolvedTheme, forcedTheme } = useTheme();
  const baseTheme = useMemo(() => {
    if (forcedTheme) {
      return forcedTheme === 'dark' ? 'dark' : 'light';
    }
    return resolvedTheme === 'dark' ? 'dark' : 'light';
  }, [resolvedTheme, forcedTheme]);

  const mapStyle = useMemo(() => {
    if (!mapStyleConfig) {
      return undefined;
    }
    if (baseTheme === 'dark') {
      return mapStyleConfig?.dark;
    }
    return mapStyleConfig?.light;
  }, [baseTheme, mapStyleConfig]);

  return (
    <BaseMap
      type="base"
      baseTheme={baseTheme}
      initialViewState={
        bounds
          ? {
              bounds,
              fitBoundsOptions: {
                padding: 30,
              },
            }
          : undefined
      }
      token={token}
      mapStyle={mapStyle}
      onClick={e => {
        console.log(e.lngLat);
        console.log(e.features);
      }}
      interactiveLayerIds={['journey-progress']}>
      {!options?.hideGeolocate && (
        <GeolocateControl
          style={{
            backgroundColor: 'rgb(var(--muted))',
          }}
          trackUserLocation
          showUserHeading
          onError={e => console.log(e)}
          positionOptions={{ enableHighAccuracy: true }}
          onOutOfMaxBounds={e => console.log('out of bounds', e)}
        />
      )}
      <UserTilesSource stravaId={stravaId} version={tileVersion}>
        <JourneyLayers journeyId={journeyId} />
      </UserTilesSource>
      <JourneyLiveLayers journeyId={journeyId} user={user} />

      <RouteStartEndMarkers
        startDate={startDate}
        startPoint={startPoint}
        endPoint={endPoint}
      />
      {options?.showStreetferretBranding && (
        <div className="absolute bottom-2 left-2">
          <Button variant={'ghost'} className="!px-1" asChild size={'lg'}>
            <a href="https://streetferret.com/journeys/about" target="_blank">
              <StreetFerretLogoWithText />
            </a>
          </Button>
        </div>
      )}
      {children}
    </BaseMap>
  );
}

export function LiveMapErrorState() {
  return (
    <div className="h-full w-full bg-black">
      <div className="flex h-full w-full items-center justify-center">
        <div className="text-white">
          <p>Error loading live map</p>
        </div>
      </div>
    </div>
  );
}

export function LiveMapLoadingState() {
  return <div className="h-full w-full bg-black" />;
}
